module controllers {
    export module consigment {
        interface IConsignmentCreateScope extends ng.IScope {
        }

        export class consignmentExportCreateExistingCtrl
        {
            static $inject = ["$scope", "$rootScope", "consignmentService", "$timeout", "generalService", "$state", "entityService", "shipperService", "consigneeService", "counterService", "consignmentTypeService", "$q", "bsLoadingOverlayService", "routeCodeService", "isInbound"];
            
            //Entity Variables
            ownerEntityId: number;
            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;

            //Shipper Variables
            shipperModel: interfaces.applicationcore.IDropdownModel;
            shipperList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            //Consignee Variables
            consigneeModel: interfaces.applicationcore.IDropdownModel;
            consigneeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            customNumber: string;

            //Counter Variables
            consignmentCounterId: number;
            counterTypeDefer: ng.IDeferred<any>;
            consignmentCounterList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            consignmentCustomNumber: boolean = false;

            //Consignment Type Variables
            consignmentTypeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            consignmentTypeDefer: ng.IDeferred<any>;
            TypeId: number;

            //Route Code Variables
            loadingCountryID: number;
            
            routeCode: interfaces.applicationcore.IDropdownModel;
            routeCodeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            //Consignment Variables
            consignmentSelected: interfaces.applicationcore.IDropdownModel;
            consignmentList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            loadPromises: ng.IPromise<any>[];

            allowCustomConsignmentCounter : boolean = false;

            customNumberCheck: boolean = false;

            constructor(private $scope: IConsignmentCreateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private shipperService: interfaces.master.IShipperService,
                private consigneeService: interfaces.master.IConsigneeService,
                private counterService: interfaces.master.ICounterService,
                private consignmentTypeService: interfaces.logistics.IConsignmentTypeService,
                private $q: ng.IQService,
                private bsLoadingOverlayService,
                public routeCodeService: interfaces.master.IRouteCodeService,
                public isInbound: boolean
            ) {
                //Get Entities Data
                this.loadPromises = [];

                this.loadPromises.push(this.loadEntities());
                this.loadPromises.push(this.getCurrentEntity());
                this.loadPromises.push(this.getCurrentCountryID());

                this.consignmentTypeDefer = $q.defer();
                this.loadPromises.push(this.consignmentTypeDefer.promise);

                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(this.loadPromises);
                });
            }

            //Load Consignments
            loadConsignments(searchText: string) {
                return this.consignmentService.GetConsignmentsDropdown(this.ownerEntityId, this.isInbound, searchText).query({
                }, (data) => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading Consignment Types
            loadConsignmentTypes() {

                this.consignmentTypeService.getDropdownList(this.ownerEntityId, this.isInbound).query({}, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    if (this.consignmentTypeDefer) {
                        this.consignmentTypeDefer.resolve();
                    }

                    this.consignmentTypeList = result;

                    angular.forEach(this.consignmentTypeList, (o) => {
                        if (o.Selected) {
                            this.TypeId = o.Id;
                            this.consignmentTypeChanged();
                        }
                    });

                }, (failureData) => {
                    if (this.consignmentTypeDefer) {
                        this.consignmentTypeDefer.reject();
                    }
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.consignmentTypeList = <ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>>[];
                });
            }

            //Loading Consignment Counters
            loadConsignmentCounter() {
                this.counterService.getDropdownList().query({
                    ownerEntityId: this.ownerEntityId,
                    counterTypeId: this.isInbound?Enum.EnumCounterTypes.ImportConignment:Enum.EnumCounterTypes.Consignment, //Import or Export Counter Type
                }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    this.consignmentCounterList = result;

                    if (this.counterTypeDefer) {
                        this.counterTypeDefer.resolve();
                    }

                    angular.forEach(result, (o) => {
                        if (o.Selected) {
                            this.consignmentCounterId = o.Id;
                        }
                    });
                }, (failureData) => {

                    if (this.counterTypeDefer) {
                        this.counterTypeDefer.reject();
                    }

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading Entities
            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                    this.loadRouteDefaults();
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getCurrentEntity() {

                return this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;

                    this.loadConsignmentTypes();
                    this.loadConsignmentCounter();
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getCurrentCountryID() {
                return this.entityService.getEntityCountry().get({
                    entityId: this.ownerEntityId
                }, (result: interfaces.applicationcore.ICountry) => {
                    this.loadingCountryID = result.Id;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading the Shippers
            loadShippers(searchText: string) {
                
                return this.shipperService.getShipperDropdown(this.ownerEntityId, searchText).query({
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading the Consignees
            loadConsignees(searchText: string) {
                return this.consigneeService.getDropdownList(this.ownerEntityId, searchText).query({
                }, () => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            customCounterChanged() {

                this.consignmentCustomNumber = !this.consignmentCustomNumber;
                this.customNumber = '';

                if (this.consignmentCustomNumber) {
                    this.consignmentCounterList = undefined;
                    this.consignmentCounterId = undefined;

                } else if (this.consignmentCounterList == undefined) {

                    var promises = [];

                    this.counterTypeDefer = this.$q.defer();
                    promises.push(this.counterTypeDefer.promise);
                    this.loadConsignmentCounter();

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.$q.all(promises);
                    });
                }
            }

            //Reload everything for the new Entity
            ownerEntityChanged() {
                this.shipperModel = undefined;
                this.TypeId = undefined;
                this.consignmentCounterId = undefined;

                this.shipperList = undefined;
                this.consignmentCounterList = undefined;

                this.consignmentTypeDefer = this.$q.defer();
                this.loadConsignmentTypes();

                this.counterTypeDefer = this.$q.defer();
                this.loadConsignmentCounter();

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.consignmentTypeDefer.promise;
                });
            }

            consignmentTypeChanged() {
                return this.consignmentService.getConsignmentTypeDefaults(this.TypeId).get((data: interfaces.consignment.ConsignmentTypeDefaults) => {
                    this.shipperModel = data.Shipper ? data.Shipper : undefined;
                    this.consigneeModel = data.ShipTo ? data.ShipTo : undefined;
                    this.routeCode = data.Route ? data.Route : undefined;
                    this.consignmentCounterId = data.consignmentCounterId ? data.consignmentCounterId : undefined;


                        this.customNumber = '';
                        this.customNumberCheck = false;

                    this.allowCustomConsignmentCounter = data.AllowCustomConsignmentCounter;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise            
            }

            //Loading Routes
            loadRouteDefaults() {

                if (this.ownerEntityId) {

                    return this.routeCodeService.getDropdownListAll(this.ownerEntityId, this.isInbound).query((result) => {
                        this.routeCodeList = result;

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
            }

            consignmentNumberChanged(model: interfaces.applicationcore.IDropdownModel) {

                if (model) {

                    return this.consignmentService.GetConsignmentDefaults(model.Id).get((data: interfaces.consignment.ConsignmentDefaults) => {

                        this.TypeId = data.ConsignmentType ? data.ConsignmentType.Id : undefined;

                        this.consignmentTypeChanged().then(() =>{
                            this.shipperModel = data.Shipper ? data.Shipper : undefined;
                            this.consigneeModel = data.ShipTo ? data.ShipTo : undefined;
                            this.routeCode = data.Route ? data.Route : undefined;
                        });

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise
                }
            }

            //Navigation
            close() {
                this.$state.go("^");
            }

            CreateConsignment() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.consignmentService.createFromExisting(

                        this.ownerEntityId,
                        this.TypeId,
                        this.shipperModel.Id,
                        this.consigneeModel.Id,
                        this.routeCode ? this.routeCode.Id : undefined,
                        this.customNumber,
                        this.consignmentSelected.Id,
                        this.consignmentCounterId,                       
                        this.isInbound         

                    ).save({

                        }, (result: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(result);

                            if(!result.HasErrorMessage) {

                                if (this.isInbound)
                                    this.$state.go("auth.ConsignmentImports.Update", { conId: Number(result.ID) });
                                else
                                    this.$state.go("auth.ConsignmentExports.Update", { conId: Number(result.ID) });
                            }
                            
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                });

            }
        }

        angular.module("app").controller("consignmentCreateExistingCtrl", controllers.consigment.consignmentExportCreateExistingCtrl);
    }
}
